

.container{width:70%;height:70%;position: absolute;top:20%;left:15%;}
.error-template {padding: 40px 15px; text-align: center;}
.container.error-template p h1{  margin-top:20px; }
.error-template #err{ width:300px; height: 300px;}
.error-actions { margin-top:22px; margin-bottom:15px; margin-right:0;}
.error-actions .btn{background-color:var(--primary-dark);border-radius: 15px;padding: 10px 20px;color: white !important;font-weight: bold;}

.content-editable {color:unset;border-radius:10px;background-color:white;min-width: 160px;width:fit-content;align-items: center;
    padding:5px;height: auto;min-height: 15px;text-align: start; } 
.content-editable:hover {background:var(--primary-dark);}
.content-editable:focus {
    background-color: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
    width:160px;
    border-radius: 10px;
}
.content-editable-compte {text-align: start !important;width:auto;padding:5px;height:fit-content;background: var(--secondary-dark);
    background: url('../img/edit.png') no-repeat right;background-size:10px;min-width: 180px;max-width:180px;font-size: 12px;
    background-color:white;border-radius:6px;font-weight: bold;align-items: center;justify-content: center;border: 1px solid gainsboro;
} 
/*i chaged the min width only*/
.content-editable-compte2 {text-align: start !important;width:auto;padding:5px;height:fit-content;background: var(--secondary-dark);
    background: url('../img/edit.png') no-repeat right;background-size:10px;min-width: 150px;max-width:180px;font-size: 12px;
    background-color:white;border-radius:6px;font-weight: bold;align-items: center;justify-content: center;border: 1px solid gainsboro;
} 
.content-editable-compte:focus {background-color: var(--primary-dark);color: white;padding: 5px;
   border: 1px solid var(--primary-dark); border-radius: 6px;outline: 0; font-size: 11px;max-width:180px ;
}
.content-editable-compte:blur {border:none;}

#com-cpt .content-editable {color:unset;padding: 0px 10px 0px 10px;width:auto;height: auto;min-width: 60px;text-align: center; } 
#com-cpt .content-editable:hover {background: var(--secondary-main);text-align: center;}
#com-cpt .content-editable:focus {
    background-color: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}
.content-editable-edit {color:unset;padding: 1px 3px ;height: auto;text-align: center !important;} 
.content-editable-edit:hover {background: var(--secondary-main);}
.content-editable-edit:focus {
    background-color: var(--primary-dark);
    color: white;
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}

.grid-wrapper-field { display: grid; grid-template-columns: auto 1fr; padding-bottom: 5px; text-align: start;align-items: center;}


.content-editable1 {color:unset;padding: 0px 10px 0px 10px;width:auto;height: auto; min-width: 60px;} 
.content-editable1:hover {background: var(--secondary-dark);background: url('../img/edit.png') no-repeat right;background-size:20px ; }
.content-editable1:focus {
    background: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
    
}
.content-editable-span {color:unset;width:auto;height: auto;} 
.content-editable-span:hover {background: var(--secondary-main);padding: 3px;}
.content-editable-span:focus {
    background-color: var(--primary-main);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}
/*.button-flex-wrapper{
    margin: 10px;
    margin-bottom: 0;
    float:left;
    text-align: center;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}*/

.button-flex-wrapper{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
    grid-gap: 5px;
}

.button-flex-wrapper2{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
}
.select-flex-wrapper{
    margin-left:1px;
    margin-top:10px;
    width: 50% !important;
    float:left;
    display: flex;
    flex-direction: row;
}
.select-flex-wrapper1{
    margin-top:10px;
    width:fit-content;
    float:left;
    display: flex;
    flex-direction: row;
}
.filtre-flex-wrapper{
    margin-left:1px;
    margin-top:20px;
    margin-bottom: 10px;
    width: 50% !important;
    float:left;
    display: flex;
    flex-direction: row;
}
.filtre-head{width:55%;grid-template-columns:repeat(3,1fr);display: grid;grid-column-gap: 10px;}
.flex-wrapper { margin: 0px; padding:0px; width:100%; display: flex; flex-direction: row; align-items:center; text-align: center;}
.flex-wrapper1 { margin-bottom: 7px; width:100%; display: flex; flex-direction: row; align-items:center; text-align: center;}

.search1{margin-right:30px;margin-bottom:3px;float:right;}
.table-header .search{
    margin-bottom: 10px;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
}
.table-header{
    display: flex;
    width: 95%;
    flex-wrap: wrap;
    /*align-items: center;*/
    margin-left: 25px !important;
    margin-top: 10px;
}
.search .MuiOutlinedInput-root {
    position: relative;
    border-radius: 10px;
    background-color: #f6f6f6 !important;
}
.search .MuiOutlinedInput-inputMarginDense{
    padding-top:5.5px;
    padding-bottom: 5.5px;
}

.table-header1{margin-right:5px;margin-left: 20px;}
.info{top:5%}
.group{margin-right:'10%';}

.btn img { width: 10px;}
.fieldsetTop {
    margin: 0px 30px;
    background: var(--primary-light);
    border: solid 2px var(--primary-darker);
    border-radius: 12px;
    padding: 3px 20px;
}

.fieldsetTop1 {
    width: 50rem;
    margin: 0px 30px;
    background: var(--primary-light);
    border: solid 2px var(--primary-darker);
    border-radius: 12px;
    padding: 3px 20px;
}

.fieldsetTopC {
    width: 30rem;
    margin: 0px 30px;
    background: var(--primary-light);
    border: solid 2px var(--primary-darker);
    border-radius: 12px;
    padding: 3px 20px;
}
.fieldsetTop2 { margin: 5px 15px;background: #f1f1f1; border: solid 2px #585858; border-radius: 8px; padding: 5px 20px;}
.fieldsetTop3 { margin: 5px;background: #f1f1f1; border: solid 1px #585858; border-radius: 8px; padding: 8px 20px;}
legend {
    padding: 3px;
    background: var(--primary-darker);
    color: #fff;
    font-size: 90%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    font-weight: 700;
}
div.rowGrid {
    display: grid;
    margin: 6px 0;
}
span.desc{
    color:gray;
    margin-right: 5px;
}
span.inf{
    font-weight: 700;
    min-width: 65px;
    padding: 3px;
    background-color: #d4cfcf;
    border-radius: 3px;
    box-shadow: 1px 1px 1px grey;
    margin-right: 15px;
    font-size: 13px;
}

table.data {
    border-spacing: 0;
}
table.data thead th {
    color: white;
    background-color: black;
    padding: 1px 5px;
    font-size: 14px;
    font-weight: normal;

}
table.data td {
    font-weight: bold;
    font-size: 13px;
    text-align: center;
}
table.data tr:nth-child(even) {background:#FFF }
table.data tr:nth-child(odd) {background: #ebebeb}

/*.nodata{width:50%;height:40%;position:relative;left:25%;margin-top:5%;bottom:10%;text-align: center;}*/
.nodata{width:fit-content;height:fit-content;display:grid;    justify-items: center;justify-content: center !important; align-items: center!important; margin:auto; }
.nodata .notFound{justify-self: center;width:850px;position:relative; ;right: 20px;}
.nodata .notFound1{justify-self: center;width:550px;position:relative; ;right: 20px;}
/*.nodata .message{color:gray;font-size: 26px;}*/
.nodata .notAllowed{width:300px;height:307px; display: inline;margin-bottom:20px;padding:20px 30px}
.nodata .message{color:gray; padding:0; font-size: 26px; font-family: 'Signika', sans-serif;}


#fiche_comp{ width:98%; height:fit-content;border: 1px solid var(--secondary-main);margin-left: auto;margin-right: auto;}
#fiche_comp #head-fiche{width:100%; height:100%;}
.img-clt {cursor: pointer;height: 220px;display: flex;justify-content: center !important;align-items: center !important;}
#formadd{width:100%;display: flex;justify-content: right !important;margin-left: auto;}
/*#formadd { grid-area: main;}*/
#fiche_info{display: grid;grid-gap:30px;padding-top: 10px;height: fit-content;grid-template-columns: 20% 75%;margin-bottom: 5px;}
#fiche_info #map{width:150px;height:150px;margin:0 0 0 5px;}
#infos-cpt{display: grid;grid-template-columns: 40% 35% 22%;grid-gap: 15px;align-items: center;}
#infos-cpt #item{display: flex;grid-gap: 10px;font-size: 13px;align-items: center;margin-bottom: 5px;}
#Circle-icon{ width: 30px; height: 30px; border-radius: 50%; display: flex; justify-content: center !important; text-align: center; align-items: center;
              color: black;font-weight: bold;background-color: #ccc;}

#fiche_chart{width:100%;border-top: 1px solid var(--secondary-main);margin-top:10px;background-color: var(--secondary-light);}
#fiche_chart .chart{width:88%; height:auto;margin-top:0.4%;margin-left: auto;margin-right: auto;}
#fiche_chart .chart .titleCharts{text-align: center;font-weight: bold;margin-top: 2%;}
.compAllow{width:auto;height: auto;margin: auto;}

.header-prv{width: 96%;height: auto;background-color:var(--secondary-light) !important;margin-left: 2%;margin-bottom:15px;}
.header-prv .head{margin-left: 2%;}

.accord-table{ padding: 0; margin: 0; width: 100%; border: none;}

/* Overriding element hover of multiselect*/
.optionContainer li {
    border:0.1px solid lightgray;
}
.optionContainer li:hover,
.optionContainer .highlight {
  background: var(--secondary-main) !important;
  color: black;
}
#icon{width:8;height: 36px}
#iconComp{width:8;height:16px;margin-right: 15px;}
#icon1{width:16px;height: 20px;margin-top: 1%;}

.button-flex-wrapper{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    vertical-align: baseline; 
    min-height: 45px;
}


.nosubmenu-template {
    color:var(--secondary-main) !important;
    justify-content: center;
    text-align: center;
     justify-self: center;
    }
.nosubmenu-template #err{
    width:300px;
    height: 250px;
    }

.editable-select{
    width: 100%;
    height:auto;
}
.div-vide{
    width:150px; 
    max-width:150px; 
    height: 15px;
    max-height: 20px;
    margin:auto ;
    display: inline-block;
}

#fiche_info .MuiSvgIcon-root {
    fill: currentColor;
    width: fit-content !important;
   /* height: 1;*/
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.smallSelectDT{
    width:100%;
    min-width: 120px;
    text-align:center;
    display: grid;
    padding: auto;
}
.smallSelectDT .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding:0px !important;
    height:16px !important;
    font-size:11px;
    width:auto;
    background:var(--primary-dark);
    /*border: 1px solid var(--primary-dark);*/
}

.smallSelectDT .MuiOutlinedInput-input{
    color:white !important;
    padding-top:1px !important;
    padding-bottom:1px !important;
}


.SelectDT{
    width:100%;
    min-width: 190px;
    text-align:center;
    display: grid;
    padding: auto;
}
.SelectDT .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding:0px !important;
    height:24px !important;
    font-size:12px;
    width:auto;
    background:var(--primary-dark);
    /*border: 1px solid var(--primary-dark);*/
}

.SelectDT .MuiOutlinedInput-input{
    color:white !important;
    padding-top:1px !important;
    padding-bottom:1px !important;
}


.smallSelectDT2{
    width:100%;
    min-width: 120px;
    text-align:start;
    height:16px;
}
.smallSelectDT .MuiInputBase-root,.smallSelectDT2 .MuiInputBase-root{
    height:17px;
}
.smallSelectDT2 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding:0px !important;
    height:17px !important;
    font-size:12px;
    width:auto;
    background:var(--primary-dark);
    color: white;
}
.smallSelectDT2 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input {
    padding: 2px 4px;
}
#changePass .MuiDialogContent-root {
    overflow-y: hidden;
}
.smallAuto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input,
.smallText .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0px 8px !important;
    font-size: 12px;
    height: 13px;
    font-weight: 700;
}
.smallText .MuiOutlinedInput-root{border-radius: 10px !important;height: 29px !important;background:#f8f8f8 ;}
.smallText .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 25px;
    font-size: 12px;
    font-weight: 600;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
    border-radius: 10px !important;
}
.smallText1 .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 0px 8px !important;
    font-size: 11px;
    height: 13px;
    font-weight: 600;
}
.smallText1 .MuiOutlinedInput-inputMarginDense {
    padding-top: 0;
    padding-bottom: 0;
    height: 30px;
    font-size: 10px;
    font-weight: 400;
    background: #f8f8f8 !important;
    display: grid;
    align-content: center;
}

.smallAuto .MuiOutlinedInput-adornedEnd,.smallText  .MuiOutlinedInput-adornedEnd,.smallText1 .MuiOutlinedInput-adornedEnd{ 
    background: #f8f8f8;
}
.smallAuto .MuiInputLabel-outlined.MuiInputLabel-marginDense,.smallText .MuiInputLabel-outlined.MuiInputLabel-marginDense,
.smallText1 .MuiInputLabel-outlined.MuiInputLabel-marginDense{
    font-weight: 600;
    color: grey;
    font-size: 12px;
    transform: translate(14px,8px) scale(1) !important;
   /* transform: translate(14px, 10px) scale(1) !important;*/
}

.smallAuto .MuiInputLabel-outlined.MuiInputLabel-shrink ,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink,
.smallText1 .MuiInputLabel-outlined.MuiInputLabel-shrink{
    transform: translate(14px, -6px) scale(0.75) !important;
}
.smallNote .MuiOutlinedInput-inputMarginDense {
    padding: 0;
    padding-bottom: 0;
    font-size: 11px;
    font-weight: 400;
    width:100%;
    background: #f8f8f8 !important;
}
.smallNote .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    font-weight: 400;
    color: grey;
    font-size: 12px;
}
.smallNote .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{
    background: #f8f8f8;
    border-radius: 10px;
}
.smallAuto .MuiOutlinedInput-multiline {padding: 10px 0px !important;}

#icons {  display:grid;}
#icons #row-1 {  grid-template-columns:  20px 20px 20px 20px; grid-gap: 5px;}
#icons #row-11 {  grid-template-columns:  20px 20px 20px; grid-gap:8px;align-items: center;}
#icons #row-2 {  grid-template-columns:  20px 20px ; grid-gap: 5px;}
#icons #row-3 {  grid-template-columns:  20px 20px 20px 20px 20px 20px ; grid-gap: 5px;}

.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 9px !important;
    border-radius: 10px;
}
.smallAutoFiltre .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 1px !important;
    font-size: 12px;
    height: 11px;
    font-weight: 700;
}
.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    transform: translate(14px, 7px) scale(1) !important;
    color:black !important;
    font-weight: bold;
    font-size: 13px;
}

.smallAutoFiltre .MuiInputLabel-outlined.MuiInputLabel-shrink,.smallText .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}


.smallAutoFiltre .MuiFormControl-marginDense {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.smallAutoFiltre .MuiOutlinedInput-adornedEnd{
    background: #f6f6f6;
}

.filtre-head .makeStyles-dataLabel-28 {
    color: #1e1919 !important;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    transform: translate(14px, 6px) scale(1) !important;
    font-weight: bold;
}
.filtre-head .makeStyles-inputFiltre-24 {
    padding: 5px 10px !important;
    background: #ccc;
}
.PrivateTabIndicator-colorSecondary-77 {
    background-color:var(--primary-main) !important;
}
.PrivateTabIndicator-root-75 {
    height: 3px !important;
}

/*costum tooltip*/
.tooltip {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltip:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltip:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    /*bottom: 108%;*/
    color: white;
    content: attr(data-text);
    left: 100%;
    bottom: -60%;
    padding: 4px 2px;
    position: absolute;
    z-index: 98;
    width: 160px;
    white-space:normal !important;
    pointer-events: none;

  }
  /*costum tooltip*/
 .tooltip1 {
    position: relative;
    margin: 0;
    padding: 0;
    top:'55px !important';
    transform-origin: 20% 40%;
  }

 .tooltip1:hover:after {
    font-weight:normal;
    font-size:11px ;
  }
  .tooltip1:hover:after {
    background:  rgba(63, 63, 63,0.8);
    border-radius: 5px;
    /*bottom: 108%;*/
    color: white;
    content: attr(title);
    left: 20%;
    padding: 4px 5px;
    position: absolute;
    z-index: 98;
    width:fit-content;
    white-space:normal !important;
    cursor: default;

  }
  .fieldInf{
    font-weight: 600;
    min-width: 65px;
    height: fit-content;
    min-height: 35px;
    max-height: 49px;
    padding: 3px 5px;
    background-color: #d9d9d9;
    border-radius: 5px;
    border:1px solid grey;
    outline: none;
    margin-right: 2px;
    margin-bottom: 5px;
    width:90%;
    font-size: 12px;
    text-align: start;
    line-height: 1.1;
    overflow: auto;
    min-height: 27px;
}
.fieldInf .inf{
    display:inline-block;
    box-shadow:none;
    background: inherit;
    height:16px;
    min-height:16px;
    max-height:16px;
    width: fit-content;
}
.fieldInf legend{
    padding: 1px 2px 0px 2px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    color:rgb(110, 110, 110);
    background: inherit;
}
.rdt_TableCell{
    padding: 0 8px !important;
    width: fit-content;
}

.note-container{
    width:100%;
    height: 16px;
}
.note-content {
    min-height: 15px;
    min-width: 100%;
    max-width: 100%;
    color:unset;
    padding: 0px 5px 0px 5px;
    width:auto;
    height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
    box-sizing: border-box;
    display: inline-grid;
    align-content: center;
}
/* custom scrollbar */
::-webkit-scrollbar {
    width: 15px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #cbced0;
    border-radius: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
  }
  
::-webkit-scrollbar-thumb:hover {
    background-color: #b9bbbb;
  }

@media only screen and (max-width: 850px) {
    .table-header{display: flex;width: 95%;flex-wrap: wrap;margin:auto;align-items: center;grid-gap: 10px;}
    .filtre-head{grid-gap: 10px; grid-template-columns: repeat(auto-fit, 160px);  justify-content: center;}
                .fieldsetTop {
                    margin: auto auto;
                    background: var(--primary-light);
                    border: solid 2px var(--primary-darker);
                    border-radius: 12px;
                    padding: 3px 20px;
                    width: 96%;
                }
}
@media only screen and (min-width: 550px) and (max-width: 850px) {
    #fiche_info #map {width: 90px;}
}
@media only screen and (max-width: 669px) {
    #fiche_info{display: grid;width: 100%;text-align: center;grid-template-columns: repeat(auto-fit,100%);grid-gap: 5px;
                justify-content: center !important;}
}
@media only screen and (max-width: 550px) {
    .nodata .notAllowed{width:200px;height: 200px;margin-bottom: 0px;padding: 0px;}
    #infos-cpt{display: grid;grid-template-columns: repeat(auto-fit,200px);grid-gap: 5px;justify-content: center !important;margin: 0 auto;margin-bottom: 5px;}
}
@media only screen and (max-width: 520px) {
    .error-template #err { width: 200px !important; height: 300px}
    .content-editable-compte {height: 22px;} 
}