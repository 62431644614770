#ticket{border:1px solid black;margin:1px;padding:1px; width: 7.94cm;border-radius:7px;text-align:center}
#ticket2{border:1px solid black;margin:1; width: 5;border-radius:7px;text-align:center}
:is(#ticket, #ticket2) #titre{color: gray;}
:is(#ticket, #ticket2) #ticketHead{text-align:center;height:fit-content ;margin-bottom: 5px;}
#ticket #ticketHead h3{font-size:15px}
#ticket #ticketHead p{font-size:13px;font-weight: 600;}
#ticket2 #ticketHead h3{font-size:14px}
#ticket2 #ticketHead p{font-size:12px; font-weight: 600;}
:is(#ticket, #ticket2) #divinfo{text-align:center}
:is(#ticket, #ticket2) #divinfo #row1{display:grid;grid-template-columns:30% 30% 39%;padding:20px}
:is(#ticket, #ticket2) #row2{display:grid;grid-template-columns:70% 30%;padding:20px}
:is(#ticket, #ticket2) #prod{display:grid;grid-template-columns:65% 35%;padding:20px;text-align:center}
:is(#ticket, #ticket2) #ticketHead #logo_ticket{  height:50px; margin:0; padding: 0; }
.bon_digit #logo_ticket{  height:50px; margin:0; padding: 0; }

:is(#ticket, #ticket2) #row-1{grid-template-columns: auto auto auto; font-size: 12px; align-items: center;margin: 5px 2px}
 #ticket2 #row-1{font-size: 9px; }
:is(#ticket, #ticket2) #row-1 p:first-child{text-align: start;}
:is(#ticket, #ticket2) #row-1 p:last-child{text-align: end;}
:is(#ticket, #ticket2) #row-2 p:first-child{text-align: start;}
:is(#ticket, #ticket2) #row-2 p:last-child{text-align: end;}
:is(#ticket, #ticket2) #row-2{grid-template-columns: 50% auto; font-size: 12px; margin-bottom: 5px;margin: 5px 3px;grid-gap: 0px !important;}
#ticket2 #row-2{font-size: 9px;}
:is(#ticket, #ticket2) #row-3{grid-template-columns: auto auto;justify-items: end;font-size: 13px;margin: 5px 3px}
#ticket2 #row-3{font-size: 12px;}
:is(#ticket, #ticket2) #row-4{justify-items: end; font-size: 13px; margin-top: 15px;}
#ticket2 #row-4{font-size: 12px;}
.scaleTick{
  transform:scale(0.55,0.55) translate(-110px,-500px);

}
.ticketPaperContent{
  padding: 0px !important;
  width:8cm;
}
.ticketPaperContent2{
  padding: 0px 0px !important;
  width:5.7cm;
  margin-right: auto;
}

#ticket .rdt_TableCell div:first-child{
  white-space: pre-wrap !important;
  text-align: start !important;
  width: 100%;
  padding-left: 1px;
}
#prodName{
  white-space: pre-wrap !important;
  text-align: start;
}

#ticket2 .gfHBVC div:first-child{
  white-space: pre-wrap !important;
}
.dash{
    border: 0 none;
    border-top: 2px dashed #322f32;
    background: none;
    height:0;
    margin-top: 10px;
    width: 292px;
  } 
.dash2{
    border: 0 none;
    border-top: 2px dashed #322f32;
    background: none;
    height:0;
    margin-top: 10px;
    width: 219px;
  } 
#bon{grid-template-columns:270px 200px; grid-gap: 14px;display: grid;margin-top: 15px;}
.bon-ach{width:96%;height: auto;margin-left: 2%;margin-top:1%;}
.bon-ach .bon-head{width:96%;height: auto; display: flex;margin-top: 15px; padding: 5px;margin-left: 2%;margin-bottom: 2%;}
.bon-ach #infoG{width:45%;height: auto;}
.bon-ach #infoD{width:45%;height: auto;text-align: end;}
.bon-ach #logo{height:70px;}
.bon-ach #infos{margin-bottom: 5px;}
.bon-ach #mtn{font-size: medium;margin-top: 20px;text-align: end;margin-right:14px;}
.bon-ach #solde{width:100% ;text-align: end; }
.bon-ach #solde #a{margin:20px 2%;padding: 0 5px; display: block;width:200px ; float: right;}
.bon-ach #solde #b{margin:20px 2%;padding: 0 5px; display: block;width:200px ; float: left;}
.bon-ach #solde #a #mtn{ border: 1px solid black; margin:0; padding: 3px; background: var(--secondary-main);text-align:right !important;}
.bon-ach #solde #b #mtn{ border: 1px solid black; margin:0; padding: 3px; background: var(--secondary-main);text-align:left !important;}
.bon-ach #solde #mtnM{ border: 1px solid black;border-top: none;border-bottom: none; margin:0;padding: 5px;background: var(--secondary-main);text-align:right !important;}
.bon-ach #total-sold{ width: 96% !important; border: 1px solid black;}
 #tr{ width: 100%;}

.boncat{width:96%;height: auto;margin-left: 2%;margin-top:2%;margin-bottom: 2%;}
.boncat .bon-head{width:96%;height: auto; display: flex;margin-top: 15px; padding: 5px;margin-left: 2%;margin-bottom: 2%;}
.boncat #infoG{width:85%;height: auto;}
.boncat #logo{height:70px;}
.boncat #infos{margin-bottom: 5px;}

.codeQr{
  width:292px;
  text-align:center;
  padding-top:20px;
}
.codeQr2{
  text-align:center;
  padding-top:20px;
  width:219px;
}

.gridDetProd{
  display:grid;
  grid-template-rows:auto 220px auto  auto  25px;
  justify-items:center;
  text-align:center;
  width:100%;
  padding:0px;
  grid-gap:5px
}
.gridDetProd2{
  display:grid;
  grid-template-rows:auto auto 50px auto  auto  25px 25px 25px;
  justify-items:center;
  text-align:center;
  width:100%;
  padding:0px;
  grid-gap:5px
}
:is(.gridDetProd,.gridDetProd2) .infoPrice{
  display:grid;
  grid-template-columns:30px auto;
  align-items:center;
  margin:5px 0;
}
.gridDetProd .infoPaper{
  display:flex;
  width:100%;
  min-height:60px;
  max-height:110px;
  background-color:var(--secondary-light);
  overflow:auto;
  padding: 10px 5px !important;
  align-items:center;
  justify-content:center;
  margin:5px 0 10px 0 ;
}
.gridDetProd .infoPaper p{
  height: 100%;
  overflow: auto;

}
.gridDetProd .information{
  margin-top:8px;
  font-size:11px;
  font-weight:600;
}



#print_row_1{display: grid;grid-template-columns:50% 49.5%; align-items: center;grid-column-gap: 3px;}
#print_row_2{display: grid;grid-template-columns:55% 44.5%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_2{display: grid;grid-template-columns:50% 50%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#print_row_22{display: grid;grid-template-columns:55% 41%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_22{display: grid;grid-template-columns:50% 50%; align-items: center;grid-column-gap: 3px;margin-left: 13px;}
#reprint_row_1{display: grid;grid-template-columns:40% 40% 19%; align-items: center;grid-column-gap: 3px;margin-bottom: 5px;}
#autocomp{margin-left: 13px;margin-right: 13px;}
#print-prod{margin-bottom: 15px;}
#print-det{display: grid;grid-template-columns:65% 34%; align-items: center;grid-column-gap: 3px;}
#print-det .MuiCircularProgress-root {
    display:flex;
    position: relative !important; margin: auto !important;
}
#print-det table {
    /*width: 98%;*/
    background-color: rgba(255, 255, 255, 0.5);  
    text-align: center;
    border: 1px solid var(--secondary-main);
}
#paper-p{overflow: auto;}
#print-det thead {
    box-shadow: 0 5px 10px white;
    background-color: var(--secondary-main);
  }
#print-det th {font-size: 15px;  }
#print-det td {font-size: 13px;  }
#print-det tr:nth-child(odd) {background:#FFF ;}
#print-det tr:nth-child(even) {background:var(--secondary-light)}
#re{align-items: center !important;}
/*p{text-align: center;justify-items: center;font-family: fantasy;}*/

#icon-reset{float: right !important;margin-bottom: 10px;}
#logos-tiket{display: grid;grid-template-columns:30% 30%; align-items: center;grid-column-gap:75px; }

#head-tiket{display: grid;grid-template-columns:50% 49%; align-items: center;grid-column-gap: 3px;font-size: 12px;margin-top: 10px;}
#info-tiket{text-align: end;margin-bottom: 10px;}
#prd-tiket{font-weight: bold;font-size: 16px;}
#info-prd-tiket{display: grid;grid-template-columns:40% 58%;grid-column-gap:11px;font-size: 12px;margin-top: 4px;}
#serial-ticket{font-size: 12px;margin-bottom:10px;margin-top:6px;}
#prod-logo{text-align: center;}
#digit-ticket{font-size: 16px;font-weight: bold;text-align: center;}
#foot1,#foot2,#foot3{font-size: 13px;text-align: center;}
#foot1,#foot2{text-align: center;margin-right: 7px;}
#foot3{text-align: center;}
#tab-reprint{display:grid;grid-template-columns:55% 45%;grid-column-gap:10px;align-items: center !important;}
#card-check{display: grid;align-items: center;grid-template-columns:50% 30% 10%;height:32px !important;font-size: 12px;grid-gap: 10px;}
#card-tick{text-align: end !important;}
#p1{font-weight:bold;width:5cm !important;text-align: center;height: 0.6em;
     border-bottom: 2px dashed black;margin-bottom: 0.5em;font-size:15px;}
#p1:first-line{background-color: white;}
#p2{font-weight:bold;text-align: center;height: 0.6em;
     border-bottom: 2px dashed black;margin-bottom: 0.5em;font-size:15px;}
#p2:first-line{background-color: white;}
#myDialog{visibility: hidden;}
#reprint-select{height:200px;overflow: auto;}
#dup-tick{position:absolute;top:0;float: right;}
/*@media print {
@page {
    size:50mm 82mm !important;
}
}*/

@media only screen and (min-width: 150px) and (max-width: 850px) {
    #saisie #row-1{ grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}  
    #charge #row-1{grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(165px,1fr));justify-content: center;}
    #prv #row-1{grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #re #reprint_row_1{grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #print-det{ display: block;}
    #paper-print{margin-top: 10px;margin-left:6px;}
#promoForm{
    display: grid;
    gap: 10px;
  }

#nbrPromo {
   display:flex;
   gap:10px;
   align-items: center;
}
#nbrPromo p{
   color: green;
}
}