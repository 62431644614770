/* @import "theme.css";
@import "sprite.css";
@import "validator.css";
@import "ext/livedit.css"; */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&display=swap&text=0123456789");
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=regular');
body{
    background-color: #FFF!important;
    font-family: "Varela Round", sans-serif;}
div#global {
   width:100%;
   display:inline-block;
}
div#header,div#content,div#footer {
   /*max-width: 1400px;*/
   width: 100%;
   margin:auto;
}
div#header {
   height: 86px;
   top:0;
   background-image: url(../img/gplay.png);
}
div#menu {
   width: calc(100% - 280px);
   height: 30px;
   vertical-align: top;
   display: inline-block;
   font-weight: bold;
   padding: 5px 0 0 10px;
}
div#menu ul {
   margin: 0;
   padding: 0;
   list-style: none;
   height: 31px;
}
div#menu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 31px;
   margin-right: .6em;
}
div#menu ul li a {
   text-decoration: none;
   color: #626262;
   font-size: .8em;
}
div#menu ul li a:hover {
   color:black;
}
div#menu ul li a.selected::after {
   background-color: var(--primary-dark);
   content: '';
   position: absolute;
   z-index: -1;
   left: 50%;
   bottom: -8px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}
div#infos {
   display: inline-block;
   height: 35px;
   vertical-align: top;
   padding-top: 0px;
   text-align: right;
   width: 230px;
}
div#infos label.mtn {
	color: var(--primary-dark);
}
div#infos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color: #626262;
	padding-right: 10px;
}
div#infos span#userName {
   font-size: .7em;
   font-weight: bold;
   color: #108000;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
   cursor:pointer;

}
div#infos span#userName::after {
   color: black;
   font-weight: normal;
   
}
div#infos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
}
div#infos img#icoUser {
   width: 20px;
   height: 20px;
   position: relative;
   top: 3px;
}
div#infos form#f {
   display: inline-block;
   margin-bottom: 7px;
}
div#infos select {
   border: solid 1px var(--primary-dark);
   font-size: .6em;
   width: 80px;
   vertical-align: super;
}
div#sMenu {
   width:calc(100% - 20px) ;
   height: 32px;
   background-color: var(--primary-dark);
   display: inline-block;
   font-weight: bold;
   vertical-align: top;
   padding-left: 20px;
}
div#sMenu ul {
   margin: 0;
   list-style: none;
   height: 32px;
}
div#sMenu ul li {
   position: relative;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 32px;
   margin-right: .7em;
   color: white;
   padding: 3px 5px 9px 5px;
}
div#sMenu ul li a,.link2 a {
   text-decoration: none;
   color: inherit;
   font-size: .9em;
}
div#sMenu ul li:hover,.link2 a:hover {
   color:#9C9C9C!important;
}
div#sMenu ul li a.selected2::after {
   background-color: var(--primary-main);
   content: '';
   position: absolute;
   z-index: 1;
   left: 50%;
   top: 24px;
   margin-left: -11px;
   width: 16px;
   height: 16px;
   transform: rotate(45deg);
}
div#contentTitle {
   width:calc(100% - 10px) ;
   height: 18px;
   border-bottom: solid 1px;
   padding-right: 10px;
   font-size: .9em;
   font-weight: bold;
   background-color: var(--primary-main);
   color: white;
   text-align: right;
   z-index: 1;
   position: relative;
}
.content {
   /*border-top: 0;
   border-bottom: 0;*/
   overflow: auto;
   /*display: block;*/
   height: calc(100% - 105px);
   /*margin-top: 0px;
   padding-left:0px;
   padding-right:0px;
   width: 100% ;*/
   top:86px;
   white-space: pre-line;
   position: fixed;
   bottom:19px;
   left:50%;
   transform: translate(-50%, 0);
   width:100%;
   max-width: 2500px;
   max-height: 2000px;
   margin: 0 auto;
}
div#main {
   width: calc(100% - 160px);
   height:calc(100% - 5px);
   display:inline-block;
   vertical-align:top;
}
div#marge {
   width:150px;
   height:calc(100% - 5px);
   /* background-color:rgba(128; 128; 128; 0.11); */
   display:inline-block;
}
div#footer {
   border: solid 1px;
   height: 18px;
   background: var(--primary-main);
   bottom:0;
   position: fixed;
   color: white;
   display: flex;
}
div#footer1 {
   border: solid 1px;
   height: 18px;
   background: var(--primary-main);
   bottom:0;
   position: fixed;
   color: white;
   display: block;
}

/* div#footer #logoDA{
   background: url(../img/logo_digitarts_boutiqa.png) no-repeat;
   width:100px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 100px;
}
div#footer #logoLicence{
   background: url(../img/logo_iraha_white.png) no-repeat;
   width:70px;
   height:31px;
   margin-left: 5px;
   margin-top: 2px;
   background-size: 70px;
} */
div#footer span#copyright, div#footer span#lastMAJ, div#footer span#powered {
   font-size: .8em;
   font-weight: bold;
}
div#footer span#copyright{
   position: absolute;
   bottom: 0;
   left: 0;
   margin-left: 5px;
   
}
div#footer span#copyright1{
   width: 100%;
   height: 100%;
   position: absolute;
   bottom: 0;
   text-align: center;
   font-size: 11px;
}
div#footer span#lastMAJ{
   position: absolute;
   bottom: 0;
   right: 0;
   margin-right: 10px;
}
div#footer span#powered{
   position: absolute;
   bottom: 0;
   left: calc(50% - 140px);
}

/* mobile*/
.link {
   text-decoration: none;
   color: var(--primary-main);
   font-size: 1rem;
}

div#minfos {
   display: inline-block;
   height: fit-content !important;
   width: fit-content !important;
   vertical-align: top;
   padding-top: 0px;
   text-align: right;
   align-self:right;
   flex-grow: 1;
   color:white;
}
div#minfos span#myCaiss {
	display: block;
	font-size: .7em;
	font-weight: bold;
	text-decoration: none;
	color:white;
	padding-right: 10px;
}

div#minfos span#userName {
   font-size: .7em;
   font-weight: bold;
   color:white;
   text-decoration: none;
   margin-left: 4px;
   vertical-align: super;
}
div#minfos span#userName::after {
   /*content:' sur  ';*/
   color:white;
   font-weight: normal; 
}
div#minfos span#userName a {
   color:white;
   font-weight: normal; 
}
div#minfos img#icoLogout {
   width: 15px;
   height: 15px;
   position: relative;
   top: 4px;
   color:white;
}

.link2 {
   position: relative;
   text-decoration: none;
   display: inline-block;
   list-style-type: none;
   overflow: hidden;
   height: 32px;
   margin-right: .7em;
   padding: 3px 5px 9px 5px;
   color:var(--primary-dark)
}
.mselected2{
   color:#9C9C9C!important;
}
.changePassword{
   width:90%;
   height: fit-content;
   max-width:350px;
   margin: auto;
   padding: 30px 10px 10px 10px;
   background: var(--secondary-main);
   border: 1px solid var(--secondary-light);
   border-radius:5px;
   text-align: end;
}
#passValidator{
   width:95%;
   margin: auto;
   margin-bottom: 30px;
}
.changePassPage{
   padding:20px 2%;
}

@media only screen and (min-width: 551px) and (max-width:845px) {
   .content {
      /*border-top: 0;
      border-bottom: 0;*/
      overflow: auto;
      height: calc(100% - 105px);
     /* margin-top: 5px;
      padding-left:0px;
      padding-right:0px;
      width: 100% ;*/
      top:65px;
      position: relative;
      /*bottom:19px;*/
      max-width: 2000px;
      margin: 0 auto;
   }
}
@media only screen and (max-width: 550px) {
   .content {
      /*border-top: 0;
      border-bottom: 0;*/
      overflow: auto;
      height: calc(100% - 105px);
     /* margin-top: 5px;
      padding-left:0px;
      padding-right:0px;
      width: 100% ;*/
      top:65px;
      position: fixed;
      /*bottom:19px;*/
      max-width: 2000px;
      margin: 0 auto;
   }
}