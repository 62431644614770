#allCmd{width: 95%;margin: 0 auto;margin-top: 10px;}
#card-cmd{background-color:#eee;width:'98%';height: fit-content;margin-bottom: 5px;}
.client-profile-img-div {width: 150px;height: 150px;border-radius: 50%;object-fit: cover;padding: 3px;}
.client-profile-img{width: 150px;height: 150px;border-radius: 50%;object-fit: cover;}
#div-com-content{display: grid;grid-template-columns: 20% 80%;}

/*.RightTriangle:nth-of-type(1):before {content: ''; width: 0; height: 0; border-style: solid; border-width: 150px 150px 0 0;
    border-color: transparent transparent #d30000 transparent;line-height: 0px;_border-color: #000000 #000000 #d30000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000'); position: absolute; top: 0; z-index: -1; left: 50%; margin-left: -150px;}
.RightTriangle {font-size: 16px;color: #ffffff; text-align: center;line-height: 130px;position: relative; }*/
.up { width: 0px; height: 0px;border-style: solid;border-width: 170px 170px 0 0;float: right; transform: rotate(90deg); } 
.up p { text-align: end; top: -130px; left: -28px; position: relative; width: 150px; height: 93px; margin: 0px;
        transform: rotate(-90deg);color: black;font-weight: bold;}
.up .p-stat { margin: 0 auto; top: -220px; left:40px; font-size: 16px; position: relative; width: fit-content; height: 35px;
              transform: rotate(-45deg);color: white;align-items: center;display: flex;grid-gap: 2px;font-weight: bold;}
#info-client{max-width: 150px;background-color:rgba(0,0,0,0.3);display: grid;grid-gap: 3px;justify-content: center;padding: 5px;
             margin-top: 6px;align-items: center;}             
#com-btn{width:95%;margin:15px auto;display: grid;grid-template-columns:250px auto 180px;align-items: center;grid-gap:5px;}             
#stepper{width:100%;margin:20px auto;}             
#card-cmd .css-46bh2p-MuiCardContent-root:last-child{
    padding:3 !important;
}
 
#number-input input[type='number']::-webkit-inner-spin-button,#number-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#number-input .css-v4u5dn-MuiInputBase-root-MuiInput-root::after{ border-bottom:2px solid green !important;font-weight: bold;}
#date-picker .MuiSvgIcon-root{font-size: 18px;}
#date-picker .MuiFormControl-marginDense{margin-top: 4px !important;}
#hr{border-top: 1px solid gray; height: 25px; margin-right: 5px;}
#tot-cmd{
  background-color: red;padding:5px;color: white;font-weight: bold;width: fit-content;min-width: 100px;text-align: center;
  font-size: 18px;float: right;margin-bottom: 15px;display:flex;grid-gap:10px
}
#head-cmd{display: grid;grid-template-columns: 44% 14% 14% 14% 14%;padding: 3px 5px 3px 5px;font-size: 13px;font-weight: bold;text-align: center;
          background-color:rgb(172,233,175,1);align-items: center;}
#head-cmd-detail{display: grid;grid-template-columns: 70% 15% 15%;padding: 3px 5px 3px 5px;font-size: 13px;font-weight: bold;text-align: center;
          background-color:rgb(172,233,175,1);align-items: center;}
#prod-cmd{display: grid;grid-template-columns: 44% 14% 14% 14% 14%;padding: 3px 5px 3px 5px;font-size: 13px;text-align: center;
          align-items: center;}
#prod-cmd-detail{display: grid;grid-template-columns: 70% 15% 15%;padding: 3px 5px 3px 5px;font-size: 13px;text-align: center;
          align-items: center;font-size: 14px;font-weight: bold;}
#prod-cmd-col{display: flex;align-items: center;font-size: 13px}
.title-cmd{display: grid;grid-template-columns: 80% 20%;align-items: center;}
#cell-cmd-det{display: flex;align-items: center;grid-gap: 5px;text-align: center;width: 100%;}

#cmd-notes{display: flex;grid-gap: 15px;align-items: center;width: 100%;justify-content: center;}
.noteDiv{display: flex;grid-gap: 5px;align-items: center;}
#com-btn  .smallAutoFiltre .MuiOutlinedInput-adornedEnd{
  background: #f8f8f8;
}
#ticket-div{width: 98%;font-size: 16px;font-weight: bold;display: flex;grid-gap: 5px;cursor: pointer;}
#com-detail{width: 98%;display: grid;grid-template-columns: 50% 50%;grid-gap: 30px;}

#liv-head{display: grid;grid-template-columns:33% 33% 33%;grid-gap: 10px;padding: 3px;}
#div-print{width: 98%;margin: 10px auto;overflow-y: auto;}
#info-header{display: block;width: 96%;margin: 5px auto 15px auto;align-items: center;}
#info-header #header1{display: flex;width:100%;align-items: center;}
#info-header #header1 #date{width:50%;text-align: start;font-size: 12px;}
#info-header #header1 #titre{width:50%;font-weight: bold;text-align: end;font-size: 24px;}
#info-header #info{font-size: 15px;display: grid;grid-template-columns: 40% 27% 33%;align-items: center;padding: 5px;}
#info-header #head-src{text-align: start;}
#info-header #head-dst{text-align: end;}
#info-header #head-title {
  background-color: var(--primary-light);
  padding: 15px 4px;
  font-weight: bold;
  text-align: center;
}

/*#head-src p{font-size: 12px;margin-bottom: 6px;}
#head-title{margin-top:10px;background-color: #086634;color: white;width: fit-content;padding: 8px;height: fit-content;margin-left: auto;
  margin-right: auto;}
#head-title p{font-size: 15px;font-weight: bold;text-align: center;}
#head-title #h2{font-size: 22px;font-weight: bold;text-align: center;margin-bottom: 5px;}
#head-dst p{font-size: 12px;margin-bottom: 6px; text-align: end;}*/

#row-sign{display: flex;font-weight: bold;margin: 20px auto 10px auto;font-size: 15px;}
#row-sign #sign{margin-left: auto;margin-right: 30px;}
#row-sign #acc{margin-left: 30px;}

#mtns-fact{font-size: 12px;width:fit-content;margin: 10px 20px 0 auto ;text-align:start;}
#mtn-ltr{font-size: 12px;width: 96%;margin: 0 auto 10px auto;text-align: start;}

#tot-colis{background-color: black;padding:10px;width: fit-content;min-width:80px;margin: 10px 20px 10px auto;color: white;
  font-weight: bold;text-align: center;}

.notesContainer{
  display:grid;
  gap:10px;
  text-align:center;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  font-size:13px;
  font-weight: bold;
  justify-items: center;
}
.notesContainer .note{
  width: 100%;
  max-width: 500px;
  text-align:center;
  font-size:13px;
  font-weight:bold;
}

@media only screen and  (min-width: 551px) and  (max-width: 999px) {
  #div-com-content{display: grid;grid-template-columns: 27% 73%;}
  #stepper{margin-left: 38px;}
}
@media only screen  and  (max-width: 550px) {
  #div-com-content{display: block;}
  #com-btn{margin-top: 5px;margin-left:0px !important;}
  #stepper{margin-left: 0px !important;width:100%;}
  #card-cmd{overflow-x: auto !important;max-width: 100%;}
  #com-btn{width:100%;display: grid;grid-template-columns: repeat(auto-fit,250px);grid-gap: 5px;margin-left: auto;margin-right: auto;justify-content: center;}             
  #info-com{display: flex;align-items: end;grid-gap:8px;}
  .client-profile-img-div {padding:0px;}
  #commades-det{width: fit-content;}
  #head-cmd,#prod-cmd{display: grid;grid-template-columns: 44% 27% 27% 27% 25%;grid-gap: 10px;}
  .title-cmd{display: block;}

}
#hidden-for-screen {
  display: none;
}
#devider{width: 80%;margin: 10px auto;}

/*#div-left{
  background: url('../img/divL.png') no-repeat;
  background-size: 270px;
  width: auto;
  height: 88px;
  display: inline-block;
}
#div-mdl{
  background: url('../img/divM.png') no-repeat;
  background-size: 270px;
  width: 280px;
  height: 120px;
  display: inline-block;
}
#div-rigth{
  background: url('../img/divR.png') no-repeat;
  background-size: 260px;
  width: auto;
  height: 88px;
  display: inline-block;
}*/

@media print {
  @page :first {
    margin: 1cm;
  }

  /* Styles pour les pages suivantes */
  @page {
    margin:20px;
    size: A4; 
    counter-reset: page; 
  }
  header {
    display: none;
  }
  #hidden-for-screen {
    display: block;
   /* margin-top: 23cm;*/
    page-break-before:always;
    page-break-inside: auto;
    padding:20px;
  }
  .scren-colis{
    padding:20px;
    page-break-before:always;
    page-break-inside: auto;
  }
  .screen-liv{
    padding:20px;
    page-break-inside: avoid;
  }
  #devider{
    display: none;
  }

}
