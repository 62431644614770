.stepper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 100%;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width:100px;
    margin: 0 20px; 
 }
  
  .step .date {
    font-size: 9px;
    color:black;
    margin-bottom: 5px;
    height: 7px;
  }
  
  .step .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }
  
  .step .circle.active {
    background-color: #4caf50;
  }

 /* .step .circle:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
  }*/
  
  .step .connector-container {
    display: block !important;
    position: absolute;
    top: 25%;
    left:-65%;
    width: 90%;
    height: 3px;
    z-index: 0;
    margin: 0 auto;
  }
  
  .step .connector {
    height: 100%;
    width: 100%;
    background-color: #ddd;
    position: relative;
    z-index: 0;
  }
  
  .step .connector.active {
    background-color:#4caf50;
  }
  
  .step:first-child .connector {
    display: none;
  }
  
  .step .text {
    font-size: 10px;
    color: #333;
    font-weight: bold;
    margin-top: 5px;
    height: 7px;
  }

  .connector-text {
    height: 6px;
    font-size: 10px;
    color: #888;
    margin-bottom: 5px;
    text-align: center;
  }
  

  @media only screen  and  (max-width: 550px) {
   .stepper{transform: scale(0.75);width: 100%;}
  } 