#Nco {margin-bottom:10px;}
#Nco #row-1 {grid-template-columns: auto 150px;display: grid;grid-gap: 5px;align-items: center !important;}
#Ncom #row-1 {grid-template-columns: 30% auto 150px;display: grid;grid-gap: 5px;align-items: center !important;}

#Con #row-1 {grid-template-columns:200px 200px auto 150px;display: grid;grid-gap: 5px;align-items: center;}
#row-1 .MuiFormControl-marginDense {
  margin-top: 0px;
  margin-bottom: 0px;
}
#Con1 #row-1 {grid-template-columns:100px 100px auto 100px;}
#charts-cou {width:90%;height: auto;margin-left:5px;margin-top: 2px;display: grid;grid-template-columns: 50% 50%;}


#charts-cou1 {width:50rem;height: auto;margin-left:5px;margin-top: 2px;display: grid;grid-template-columns: 50% 50%;grid-column-gap: 15px;}
#chart-cou {width:99%;margin-bottom:20px;background-color:#f6f6f6;margin: 25px;}
#chart1-cou {width:25rem;margin-bottom:20px;background-color:#f6f6f6;}
#chart-cou1 {display:flex;}
#charts-cou .titleCharts{margin-top:3px;text-align: center;font-weight: bold;}

#charts-cou1 .titleCharts{margin-top:3px;text-align: center;font-weight: bold;}

#txc #row-1 {grid-template-columns:auto auto 150px;display: grid;grid-gap: 5px;align-items: center;}

#charts-cou{width:90%;height: auto;margin-left:5px;margin-top: 2px;display: grid;grid-template-columns: 50% 50%;}
#conChar1{width:100%;height: auto;margin-left: 6%;;padding-top: 3%;}

#conChar11{width:600px;height: auto;margin-left: 8%;padding-left: 5%;padding-top: 3%;background-color: rgb(255, 255, 255);}


#charts-pie{width:98%;display: flex;flex-wrap: wrap;}
#charts-pie #chart-pie{width:44%;margin-left: 4%;margin-top: 2%;background-color: #f6f6f6;}
#charts-pie #chart-pie #charts{width:100%; margin-left: auto;margin-right: auto;}



#charts-pie #chart-pie1{width:48%;margin-left:26%;margin-top: 2%;background-color: #f6f6f6;}
#table-prod{margin-top: 2%;}
#table-prod #select-filtre{margin-bottom: 2%; background-color: teal;}
#chart-pie .titleCharts{margin-top:3%;text-align: center;font-weight: bold;}

#chart-bar{width:60%;height: 60%;position: relative;left: 18%;margin-left: 2.5%;margin-top: 2%;background-color: #f6f6f6;}

#tmp #row-1 {grid-template-columns:200px 200px 250px auto 150px; display: grid;grid-gap: 5px;align-items: center;}
#tmp1 #row-1 {grid-template-columns:100px 100px 250px auto 100px  ;}
#chart-bar-t{width:75%;height:85%;position: relative;left: 10%;margin-left: 2.5%;margin-top: 2%;background-color: #f6f6f6;}

#chart-bar-t1{width:42rem;height:85%;position: relative;left: 10%;margin-left: 2.5%;margin-top: 2%;background-color: #f6f6f6;}
#chart-bar-t .titleCharts1{margin-top:10%;text-align: center;font-weight: bold;}
#chart-bar-t .titleChartss{text-align: center; color:black;font-weight:bold !important;margin-left: 15px;margin-top:90px;}

#chart-bar-t .information {
  width: 45%;
  border: none;
  padding: 5px;
  background-color: var(--primary-darker);
  color: white;
  float: right;
  text-align: start;
  margin: 15px;
  border-radius: 5px;
}


#mtq #row-1 {grid-template-columns:200px 200px auto auto auto 150px;display: grid;grid-gap: 5px;align-items: center;}
#mtq1 #row-1 {grid-template-columns:100px 100px auto auto auto 100px;display: grid;grid-gap: 5px;align-items: center;}

#pie-mtq{width:95%;height:90% !important;margin-top: 2%;margin-left:2.5%;}
#pie-mtq #chart-pie-mtq{width:100%;height:auto;background-color: #f6f6f6 !important;}
#chart-pie-mtq .titleCharts{margin-top:3%;text-align: center;font-weight: bold;}

.custom-tooltip{width:auto;height: auto;padding:5px; background-color:white; text-align: center;margin-top:5px;}
.custom-tooltip .label{ font-weight:bold;}
.custom-tooltip .intro{color:#343435;margin-top:5px;}

.custom-tooltip-bar{width:fit-content;height: auto;padding:5px; background-color:white; text-align: center;margin-top:5px;text-align: start;padding:8px;}
.custom-tooltip-bar #label{margin-bottom:5px;}


.legend{background-color:white;}
.legend .legend-content{list-style: square;display:flex;justify-content: center; flex-wrap: wrap;}
.legend .legend-content #content{margin-left: 20px;margin-right: 5px;}



@media only screen  and  (max-width: 850px) {
  #charts-cou {display: block !important;margin-left: 0px !important;}
  #charts-pie{width:95%;display: block !important;}
  #charts-pie #chart-pie{width:95%;margin-left:5%;margin-top: 2%;background-color: #f6f6f6;}
  #charts-pie #chart-pie #charts{width:100%; margin-left: auto;margin-right: auto;}
  #chart-bar-t{width:90%;height:fit-content;position: relative;left: 5%;margin-left:0;margin-top: 2%; background-color: #f6f6f6;}
  #conChar1 {margin-left: 0%;width: 98%; }
  #chart-bar-t .titleCharts1{position: relative;bottom: 2%;text-align: center;font-weight: bold;font-size: 16px;}
  #chart-bar-t .titleChartss{position: relative;text-align: center;font-weight: bold;font-size: 16px;margin-top: 20px;}
    #chart-bar-t .information {
      width: 99%;
      border: none;
      padding: 5px;
      background-color: var(--primary-darker);
      color: white;
    float: right;text-align: start;margin:0px;border-radius: 5px;font-size: 10px;}
}
@media only screen  and  (max-width: 850px) {
  #Nco #row-1,#Ncom #row-1,#Con #row-1,#txc #row-1,#Con #row-1,#tmp #row-1,#mtq #row-1 {
    grid-gap: 10px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;
  }
}
@media only screen and  (min-width: 850px) and  (max-width: 1150px) {
  #chart-pie .titleCharts{margin-top:3%;text-align: center;font-weight: bold;}
}
