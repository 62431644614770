.table-header-ad .search{
  margin-bottom: 5px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
}
.table-header-ad{
  display: flex;
  width: 97%;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 5px !important;

}
#role { padding-top: 5px; padding-bottom:5px}
#role #row-1 {  grid-gap: 25px; grid-template-columns:auto 200px;display: grid;align-items: center;}
#priv #row-1{grid-template-columns: 80% auto;display: grid;align-items: center; grid-gap: 15px;align-items: center;}
#privilege #row-1{ grid-gap: 25px; grid-template-columns:250px auto;display: grid;align-items: center;}

#usr-div{max-height: 250px;overflow: auto;margin-top: 25px;}

#role .searchWrapper {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 3px;
  min-height: 19px;
  position: relative;
}
@media only screen and (min-width: 150px) and (max-width: 850px) {
    #role #row-1{grid-gap: 5px; grid-template-columns: repeat(auto-fit, minmax(160px,1fr));justify-content: center;}
    #privilege #row-1{grid-gap: 45px !important; grid-template-columns: repeat(auto-fit, minmax(160px,1fr)); justify-content: center;display: block;}
    .table-header-ad{
      display: flex;
      width: 97%;
      flex-wrap: wrap;
      align-items: center;
      margin-left:0px !important;
    
    }
  }