
.img-dialog{height: 200px;margin-top: auto;margin-bottom: auto;}
.div-profile{
    width:100%;
    height:100%;
    justify-content: center;
    text-align: center;
}

.content-editable-comptee {color:unset;padding: 0px 10px 0px 10px;text-align: start !important;width:auto;
                           background: url('../img/edit.png') no-repeat right;background-size:10px ;
                           height: auto;min-width: 180px; background-color:var(--main);border-radius: 3px;margin-left:3px ;} 
.content-editable-comptee:focus {background-color: var(--primary-dark);color: white;
    border: 1px solid var(--primary-dark);border-radius: 5px;outline: 0;
}

.content-editable-comptes {color:unset;padding: 0px 10px 0px 10px;text-align: start !important;width:auto;
    background: var(--secondary-dark);background: url('../img/edit.png') no-repeat right;background-size:10px ;
    height: auto;min-width: 100px;background-color:var(--main);border-radius: 3px;margin-left:3px ;} 
.content-editable-comptes:focus {
    background-color: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}

#div-photo-profil{height: auto;cursor: pointer;padding: 15px;}
.img-profil{height:500px;}
.img-componenet{height: 200px;margin-top: 30px;margin-right:0px;}
.info-expender{width:100%;height: 100%;display: grid;grid-template-columns:18% auto;background-color: #eee;grid-gap:10px}
.info-expender .div-info{height: 100%;display: grid;gap:28px;}
.info-expender .div-info #div-info1 {display: grid;grid-template-columns: 80% 18%;grid-gap: 10px;align-items: center;}
.info-expender .div-info #div-info1 #row-1,.info-expender .div-info #div-info1 #row-2 {display: grid;grid-template-columns: 5% 28% 5% 28% 5% 20%;
     align-items: center;justify-content: center;margin-bottom: 5px;}
.info-expender .div-info #div-info2 {width:90%;display:flex;flex-wrap: wrap;font-size: 12px;align-items: start !important;
                                     /*background-color: lightgray;*/margin-bottom:8px;font-size: 12px;
                                     justify-content: center;/*border: 2px solid black;*/border-radius: 15px;}
#elem-act{flex-basis: 120px;flex-grow: 0;flex-shrink: 0;align-items: center;display: grid;grid-gap: 10px;justify-content: center;text-align: center;vertical-align: middle;padding: 5px;}
#elem-act #icon-act{width: 30px;height: 30px;border-radius: 50%;background-color: #4CAF50;display: flex;
                    justify-content: center;align-items: center;color: black;font-weight: bold;margin: auto !important;}

#acts{display: grid;grid-template-columns: 49% 49%;grid-gap: 10px;align-items: flex-start;justify-content: center;}
#acts .MuiCheckbox-colorSecondary.Mui-checked {
    color: green;
}
#icon{width:36px;height: 16px;}
#icon1{width:16px;height: 16px;}
#div-info1 #rows{
    display: grid;
    gap: 5px;
}
#btn-act{
    display: flex;
    align-self: center;
}

#auto .MuiInputBase-input {
    height:0px;
    min-height: 0px;
  }
#auto .MuiInputBase-root{
    color: white;
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size:13px !important ;
    /*font-style: italic; */
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* font-weight:bold !important;*/
   /* line-height: 10px !important;*/
    letter-spacing: 0.00938em;
}
#auto .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input{
    padding: 5px 3px;
}

#cpt_new .clIVlJ {
    padding-left: 6px !important;
    padding-right: 6px !important;
}
#cpt_new .iEmSDR{
    min-width: 8px !important;
    flex: 0 0 28px !important;
}
#cpt-stat .MuiPopover-paper{top:100px !important;}