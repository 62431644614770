#col{width:100%;display: flex;margin-top: 15px;}
.forms{margin-left:25px ;margin-top: auto;margin-bottom: auto;}
.forms #input{margin-top: 20px;margin-bottom: 15px;}

.chrome-picker {
   /*  position: fixed; */
     top: 80px; 
     width:300px !important;
     height: 310px !important;
}
.content-editable-cat { font-size: 28px !important;font-weight: bold !important;color:unset;padding: 0px 20px 0px 10px;
  width:auto; margin-left:10px;height: auto;min-width: 90px;  background: var(--secondary-dark);
  background: url('../img/edit.png') no-repeat right;background-size:12px;} 

.content-editable-cat:hover {background: var(--secondary-main);}
.content-editable-cat:focus {
   
    background-color: var(--primary-dark);
    color: white;
    /*outline-color:blue;*/
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}
.content-editable-cat-ar { width:fit-content;font-size: 16px !important;font-weight:lighter;color:unset;padding: 0px 10px 0px 10px;
  background: var(--secondary-dark);background: url('../img/edit.png') no-repeat right;background-size:8px;
  width:fit-content; margin-left:10px;height: auto;min-width: 160px;max-width: 160px;text-align: start;} 
/*.content-editable-cat-ar:hover {background: var(--secondary-main); background: var(--secondary-dark);
  background: url('../img/edit.png') no-repeat right;background-size:8px;}*/
.content-editable-cat-ar:focus {background-color: var(--primary-dark);color: white;border: 1px solid var(--primary-dark);border-radius: 5px;
  outline: 0;}

.content-editable-dig { width:fit-content;font-size: 12px !important;font-weight:lighter;color:unset;padding: 0px 10px 0px 10px;width:fit-content; margin-left:10px;height: auto;min-width: 60px;} 
.content-editable-dig:hover {background: var(--secondary-main);}
.content-editable-dig:focus {
    background-color: var(--primary-dark);
    color: white;
    border: 1px solid var(--primary-dark);
    border-radius: 5px;
    outline: 0;
}

#cat-prod{width:100%;height: fit-content;display: grid;grid-template-columns: 23% auto 700px;}
#cat-prod1{width:auto;height: fit-content;display: block;}

#cat-inf{margin-top: 25px;margin-left: 35px !important;}
#cat-inf .content-editable[type=number] {text-align: start !important; margin-right: 5px !important;}
#cat-inf1{margin-top: 25px;margin-left: 5px;}

.img-cat{display: flex;justify-content: center;align-items: center;height: 200px;cursor: pointer; }
.img-cat img{max-height: 93%; max-width: 100%;}

.div-row{align-items: center;display: flex;margin-bottom: 5px !important; max-width: 280px;text-align: start !important;grid-gap: 5px;}
#div-cat-head{width: 350px;height: auto;}
#div-cat-head1{width: auto;height: auto;}
#div-cat-head #div-cat-title{align-items: center !important;display: flex !important;margin-bottom:3px; gap:10px}
#div-cat-title-2{text-align: start !important;margin-bottom: 5px;}
#div-cat-title-1{text-align: start !important;margin-bottom: 3px;}
#date-cat{margin-top: 0px;}
#div-cat-info{width: 500px;height: fit-content;margin-bottom: 5px;margin-left:2%;margin-top: 35px;}
#div-cat-infoo{height: fit-content;margin-bottom: 5px;margin-left:2%;}
#div-cat-prx{height: 30px;display: flex;width: 300px;font-size: 13px;}
#div-cat-qte{height: 30px;display: flex;border-bottom: 2px solid black;width: 300px;font-size: 17px;margin-top: 10px;margin-right: 5px;}
#div-dig-desc{text-align: left;margin-left: -10px;margin-top: 5px;}
#div-dig-desc #p{ text-indent: 15px;display: flex;align-items: center;}
#cat-check{display:grid !important;width:80%}
#cat-btn{display:flex !important;margin: 15px 0 15px 0 !important;  width: 100% !important;justify-content: end !important;}
#qte{margin-left: 10px;}

/*style for add catalogue*/
#cat{width:100%;display: block;justify-content: center;}
#cat #row-1 {grid-template-columns: 25% 25% 25% 22%;display: grid;margin-bottom: 10px;grid-gap:5px}
#cat #row-2 {grid-template-columns:41% 29% 27.5%;display: grid;grid-gap: 5px;margin-bottom:5px;align-items: center;}
#input-select .makeStyles-headInput-740 { width: 100%; height: 60px !important; min-width: 100px; min-height: 30px; /*padding-right: 10px;*/}
#input-select .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {padding: 3.5px;}
#cat #input-select .MuiInputLabel-outlined {top: -4px;}
#cat #input-ordre .MuiInputLabel-outlined {top: -4px;}
#input1 .MuiInputLabel-outlined {top: -4px;}
#cat #row-3 {display: flex;margin-bottom: 5px;}
#cat #div-file{display: flex;}
#cat1{width:100%;margin-bottom:10px;margin-top: 15px;margin-left:0px;}
#cat1 #row-1 {grid-template-columns: 50% 48%;display: grid;margin-bottom: 20px;grid-column-gap: 5px;}
#cat1 #row-2 {grid-template-columns:55% 47%;display: grid;margin-top: 15px;}
#cat1 #input-select .MuiInputLabel-outlined {top: -4px;}
#cat1 #input-ordre .MuiInputLabel-outlined {top: -4px;}
#input-ordre .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {padding: 3.5px;}
#cat1 #row-3 {grid-template-columns:55% 52%;display: grid;}
#cat1 #row-3 #input{margin-left:0px;}
#cat1 #div-file{margin-left: 0px;margin-top:0;}

#cat-form{
  width:95%;margin-left: auto;margin-right: auto;
}
#cat-form #input1 .Mui-checked,#det-prd .Mui-checked,#div-head .Mui-checked,#flags-on .Mui-checked{
  color:green !important;
}
#cat-form #input1 .MuiButtonBase-root{
  color: red;
}

#formcontrol .Mui-checked{
  color:green !important;
}
#formcontrol .MuiButtonBase-root{
  color: red;
}

.table-borderd #tr-cat{background-color:#eee;border: 1px solid #e3e2e2 !important;border-radius: 15px;}
#td-sup{background-color: white;width:28px;cursor: pointer;}
#cat-digit{grid-template-columns: 180px 20%;display: grid;/*margin-left:30px;margin-top: 15px;*/}

.catalogues .MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 8px 24px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
#catalog,#bundle{width:99%;margin:auto;}
#catalog #row-1,#bundle #row-1,#bundle #row-2{grid-template-columns:33% 33% 33%;display: grid;grid-gap:10px;margin-bottom:10px;}
#catalog #row-2{grid-template-columns:25% 25% 24% 24%;display: grid;grid-gap:10px;margin-bottom:10px;}
#catalog #row-3{grid-template-columns:33% 33% 33%;display: grid;grid-gap:10px;margin-bottom:15px;}


.descPrd {width: 90%;text-align: left !important;padding: 5px 10px;background:white;color: black; border-radius: 3px;
          border-color:var(--secondary-main);outline: 0;overflow-y: auto;  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.);border-radius: 10px;
          border:1px solid gainsboro;
        }
.descPrd:hover,.descPrd:focus {background: var(--primary-dark);color: white;overflow: auto;}

.NomPrd {width: 100%;height:25px!important;text-align: left !important;padding: 5px 10px;background: #ccc;
         color: black;border-radius: 3px;border-color:var(--secondary-main);outline: 0; overflow-y: auto;
}
.NomPrd:hover,.NomPrd:focus {
  background: var(--primary-dark);
  color: white;
}
#row-grid{display: grid;align-items: center;grid-gap: 10px;grid-template-columns: 5% 95%;margin-bottom: 15px;margin-top: 15px;font-size:13px ;}

#tab-dig .MuiTabPanel-root {
  padding:5px 0px !important;
}
#tab-dig .PrivateTabIndicator-colorSecondary-91 {
  background-color:var(--primary-main);
}

#title-fix{font-size: 26px;font-weight: bold}
.title-fix{font-size: 26px;font-weight: bold}
#div-cat-prx .content-editable{cursor: pointer;}
#div-cat-prx #b{margin-left: 5px;}

#child-1 .bsgpsK div:first-child {
  white-space: pre-wrap !important; 
 overflow: hidden;
 text-overflow: ellipsis;
}

.MuiTabs-indicator{
  background-color: var(--primary-dark) !important;
}

#card-prod{width:95%;background-color:var(--secondary-main);border: 1px solid gray;margin:10px auto 5px auto;border-radius: 5px;padding: 6px;
          display: block;}
#card-div{display: grid;grid-template-columns:220px auto 24%;grid-gap:15px;}
#card-div #card-img{height:180px;}
#div-name{display: flex;grid-gap:20px;align-items: center;}
#div-name1{display: grid;grid-template-columns: 30px 300px auto;grid-gap:20px;align-items: center;}
#div-name #prod-name{font-size: 18px;font-weight: bold;margin-top:8px;text-align: center;}
#prod-name-bq{font-size: 12px;margin-top:2px;text-align: start;}
#prx-prod{width:98%;background-color: rgba(0, 0, 0, 0.1);border: 1px solid black;padding: 8px; border-radius: 5px;
  text-align: center;font-size: 13px;font-weight: bold;margin-top: 10px;display: flex;flex-wrap: wrap;grid-gap:7px;justify-content: center;}
/*#prx-prod-elem-1{width:fit-content;min-width:65px;height: fit-content;padding: 3px;background-color: rgba(255,255,255,0.8);
  border: 1px solid gray;;border-radius: 8px;font-weight: 300 !important;}*/
#description{background-color:rgba(0,0,0,0.1) ;border:1px solid black;border-radius:15px;font-size: 12px;padding:5px;height: 95%;}
#description #title{font-size:15px;display: flex;justify-content: center !important;font-weight:bold;text-decoration: none;margin-bottom: 10px;}
#elem-title{font-size: 12px;padding-bottom:3px;font-weight: 400 !important;}
#div-det-prd{width: 98%;border: 1px solid black;background-color: rgba(0,0,0,0.1) ;margin-top: 15px;
  padding:8px;border-radius: 5px;}
#div-interc{display: flex;grid-gap: 5px ;justify-content: end;align-items: center;}
#div-interc0{display: flex;grid-gap: 5px ;align-items: center;margin-left: auto;}
#det-prd{display: flex;flex-wrap: wrap;align-items: center;grid-gap: 15px;justify-content: center;padding-left: 10px;}
#det-prd .MuiButtonBase-root{
padding: 0 !important;height: 12px;
}
#det-prd .MuiFormControlLabel-label,#div-head .MuiFormControlLabel-label {font-size: 12px;}
#btn-prd{display:block;width:100%;margin-left: auto;margin-right: auto;margin-top: 15px;margin-bottom: 5px;text-align: center;align-items: center;
  height: 100px;}

.content-editable-prod {color:unset;padding: 0px 10px 0px 10px;text-align: center !important;width:fit-content;font-size: 13px !important;
  background: url('../img/edit.png') no-repeat right;background-size:10px ;max-width: fit-content;min-width: 65px !important;
  height: auto; min-height: 16px;background-color:var(--main);border-radius: 3px;margin-left:3px ;justify-self: center;} 
.content-editable-prod:focus {width: fit-content;min-width: 65px;background-color: var(--primary-dark);color: white;
  border: 1px solid var(--primary-dark);border-radius: 5px;outline: 0;max-height: fit-content;
}
#description .content-editable-prod{background-color: rgba(0,0,0,0.0);text-align: left !important;max-height: 300px;overflow: auto;}
#description .content-editable-prod:focus{background-color: var(--primary-dark);}
.InputNbrEditable .content-editable-prod:focus{max-width: 65px !important;}

.content-editable-prod-name {color:unset;padding: 0px 10px 0px 10px;text-align: center !important;width:fit-content;font-size: 16px !important;
  font-weight: bold;min-height: 16px;background-size:10px ;max-width: fit-content;min-width: 65px; height: auto;
  background-image:url('../img/edit.png');background-repeat: no-repeat;background-position: right center; padding-right: 30px; display: flex;align-items: center; 
} 
.content-editable-prod-name:focus {width: fit-content;min-width: 65px;max-width:fit-content;background-color: var(--primary-dark);
  color: white;border: 1px solid var(--primary-dark);border-radius: 5px;outline: 0;}

.content-editable-prod-bq {color:unset;padding: 0px 10px 0px 10px;text-align: start !important;width:fit-content;font-size: 12px !important;
  background-image:url('../img/edit.png');background-repeat: no-repeat;background-position: right center; padding-right: 30px; display: flex;
  background-size:10px ;max-width: fit-content;min-width: 65px; height: auto;min-height: 16px;} 
.content-editable-prod-bq:focus {width: fit-content;min-width: 65px;max-width:80px;background-color: var(--primary-dark);color: white;
  border: 1px solid var(--primary-dark);border-radius: 5px;outline: 0;
}


#photo-card{width:fit-content;height: fit-content;max-height:250px;border: 1px solid gray ;border-radius: 8px;
            background-color: #f0eaea;display: block !important;padding: 5px;}
#photos-card{width:98%;display:flex;flex-wrap: wrap;grid-gap: 15px;margin-bottom: 50px;margin-left: auto;margin-right: auto;
           justify-content: center;}
#div-img{display: grid;justify-content: center;align-items: center;}
.image-prod{height: 100%;width: 100%;cursor: pointer;}
#div-head{display: grid;grid-template-columns: 65% 35%;align-items: baseline;text-align: center;padding: 8px;}

.MuiToggleButtonGroup-root {
  display: flex;flex-wrap: wrap !important;justify-content: center;
}
#div-ButtonG{width: 98%;margin-left: auto;margin-right: auto;display: flex;justify-content: center;}
#empty-expender{text-align: center;transform: translateY(18%);}
#product-image{height: 200px;text-align: center;width: 100%;}
#add-photo-container{display: flex;justify-content: center;align-items: center;}
#add-photo-container1{display: grid;justify-content: center;}
#photos-dialog .MuiDialogContent-root{overflow-x: hidden;}

#info-prod{display: grid;grid-template-columns:55% 45% ;align-items: center;grid-gap: 10px;}
.div-search .search{width: 95%;display: grid;justify-content: end;margin-bottom: 15px;margin-left: auto;margin-right: auto;}

#div-onl{width:98%;margin-left: auto;margin-right: auto;display: grid;grid-template-columns:520px auto;grid-gap:10px;padding: 5px;}
#div-onl #div-cat{display: grid;grid-gap:6px; grid-template-columns: repeat(auto-fit, 95px);justify-content: center;
  max-height: 650px;overflow: auto;}
#div-onl #onl-content{border:none;background-color:#eee;height: fit-content;}
#onl-content #div-nbr-prd{margin: 0px;background-color:#ccc;height: 35px;font-size: 14px;font-weight: bold;
  display: grid;align-items: center;grid-template-columns: 50% 50%;padding:0 3px 0 8px;}
#onl-prod{display: grid;grid-template-columns: 2% 13%  auto;margin-bottom: 10px;grid-gap:20px;align-items: center;width: 98%;}
#prixs{display: flex;justify-content: center;grid-gap: 10px;margin-left: 10px;}
#card-cat-onl{margin-bottom: 5px;background-color: #eee;vertical-align: middle;text-align: center;cursor: pointer;font-size:11px;
  font-weight: bold;padding:3px;height: 110px;}
.colored{background-color: var(--primary-main) !important;}
#ErreurData{font-weight: bold;font-size: 16px;padding: 5px;text-align: center;}
#dt-add-prd{width:95%;justify-content: end;font-size: 12px; font-weight: bold;display: flex;grid-gap: 3px;align-items: center;margin-bottom: 5px;}

#catalogue-info{display: grid;grid-gap: 8px;margin: 25px 0px 0px 25px;}

#bdl-row{width:98%;display: grid;grid-template-columns: 49% 49% ;grid-gap: 10px;align-items: center;justify-content: center;}
#bdl-note{width:98%;margin-left: auto;margin-right: auto;}

.bundle-info{display: grid;grid-template-columns: 39% 60%;grid-gap: 10px;align-items: center;}
#bdl-head{display: flex;align-items: center;width: 100%;grid-gap: 15px;}
.bundle-info #div-bdl-title{text-align: start;font-weight: bold;font-size: 26px;padding-left: 35px;}
.bundle-info #div-bdl-det{width:98%;text-align: start;font-weight: 300;font-size: 12px;padding-left: 55px;margin-top: 30px;display: grid;
  grid-gap: 8px;}
#div-bdl-btn{width:98%;text-align: end;margin-bottom: 10px;padding-right:30px;}
.bdl-div{display: grid;grid-template-columns: 23% auto;padding: 3px;align-items: center;background-color: #eee;}
#div-bdl-libero{text-align: start !important;margin-left:15px;font-size: 13px;width:75%;}

@media only screen  and  (max-width: 1150px) {
  #add-photo-container{display: flex;flex-wrap: wrap;}
}
@media only screen  and  (max-width: 850px) {
  .bdl-div{grid-template-columns: repeat(auto-fit,100%);}
  #catalog {margin-bottom: 15px;margin-top: 0px;}
  #div-dig-desc{margin-left:0px;}
  #cat-digit {grid-gap: 0px; grid-template-columns: repeat(auto-fit, 180px);justify-content: center;margin-left: 0 !important;}
}
@media only screen  and  (max-width: 700px) {
  #div-onl{display: grid;grid-template-columns: repeat(auto-fit, 100%);justify-content: center;grid-gap:10px;}
  #onl-prod {display: grid;grid-template-columns: 2% auto auto;margin-bottom: 10px;grid-gap: 20px;align-items: center;width: 98%;}
}

@media only screen and (min-width:550px) and  (max-width: 1280px) {
  #cat-prod{width: 100%;display:flex;flex-wrap: wrap;justify-content: center;}
  #cat-btn{display:grid !important;justify-content: center !important;}
}

@media only screen  and  (min-width: 551px) and  (max-width: 970px) {
  #card-div{grid-template-columns: 22% 50% 24%;grid-gap: 10px;}
}

@media only screen  and  (max-width: 550px) {
  #cat{width:100%;margin-left:auto;margin-right: auto;padding: 15px;}
  #cat #row-1,#cat #row-2,#cat #row-3 { grid-template-columns: repeat(auto-fit, 160px);justify-content: center;display: grid;grid-gap:10px;}
  #cat #div-file{ grid-template-columns: repeat(auto-fit, 160px);  justify-content: center; display: grid;}
  #catalog{margin-top: 0px !important;}
  #catalog #row-1,#catalog #row-2,#catalog #row-3 {grid-gap: 5px; grid-template-columns: repeat(auto-fit, 180px);  justify-content: center;
     display: grid; margin-bottom: 5px;}
  #catalog #btn-div{text-align: center !important;}
  #col{display:block;  margin-left: auto;margin-right: auto;width:95%}
  .chrome-picker { top: 30px; width:200px !important; height: 230px !important; margin-left: auto;margin-right: auto;margin-bottom: 20px;}
  .forms{margin-left: 0px !important;}
  .forms #input{margin-top: 5px;}
  #card-div{grid-template-columns: repeat(auto-fit,100%);justify-content: center;display: grid;grid-gap:10px;text-align: center !important;}
  #names{margin-left: auto;margin-right: auto;width: fit-content;}
  .bundle-info{display: block !important;background-color: aquamarine;}
}


