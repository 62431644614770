

.expender{width: 100%;height: 100%;display: grid;grid-template-columns: 23% auto;background-color:#eee;grid-gap: 15px;}
#div-pub{display: flex;justify-content: center;align-items: center;height: 200px;cursor: pointer}
.img-pub{max-height: 93%;max-width: 100%;}

.expender .expender-content{width: 100% !important;text-align: center;margin: auto;display: grid;grid-gap: 20px;}
.expender .expender-content #row-0{display: flex;grid-gap: 80px;}
.expender .expender-content #row-1{display: flex;grid-gap: 30px;}
.expender .expender-content #row-2{display: flex;grid-gap: 25px;flex-wrap: wrap;}
.expender .expender-content #div-content{display: grid;grid-template-columns: 10% 90%;align-items: center;grid-gap: 8px;}

#icon-dev{margin: 0px 5px;}
#reacts{width:28px;}
#Allreacts{display:flex;flex-wrap: wrap;margin-top:10px;}

#indf{font-size: 13px;font-weight: bold;margin-right: 5px;}


.table-borderd { border-collapse: collapse; width: 95%; height: auto;margin: 20px auto!important;}
.table-borderd th{ background-color: #eee; padding:4px;text-align: center;font-size: 13px;color:var(--primary-dark);}
.table-borderd tr {border: 1px solid #eee;height:25px;font-size: 12px;border-radius: 5px !important;}
.table-borderd td {text-align: center;}
.table-borderd #td-width{width: 250px;margin-right: 10px;}

.MuiTablePagination-toolbar {min-height: 52px;margin-right: 20px}


#new{width:95%;margin-bottom:30px;margin-top: 10px;margin-left: auto;margin-right: auto;}
#new #row-1 {grid-template-columns: 32% 32% 32%;display: grid;margin-bottom: 15px;grid-gap: 10px;}
#new #row-2 {grid-template-columns:65.5% 32%;display: grid;margin-bottom: 15px; grid-gap: 10px;}
#new #row-3 {grid-template-columns:24% 24% 24% 23%;display: grid;grid-gap: 10px;margin-bottom: 15px;}
#new #input-select .MuiInputLabel-outlined {top: -4px;}


#new1{width:100%;margin-bottom:30px;margin-top: 5px;margin-left:0;}
#new1 #row-1 {grid-template-columns: 50% 48%;display: grid;margin-bottom: 20px;grid-gap: 5px;}
#new1 #row-2 {grid-template-columns:55% 47%;display: grid;margin-left:0;margin-top: 20px;}
#new1 #row-2 #input-select{width:103%;}
#new1 #row-3 {grid-template-columns:50% 50%;display: grid;grid-gap: 5px;margin-bottom: 20px;}
#new1 #input-select .MuiInputLabel-outlined {top: -4px;}
#new1 #input-ordre .MuiInputLabel-outlined {top: -4px;}

#child-1 .bsgpsK div:first-child {white-space: pre-wrap !important; overflow: hidden;text-overflow: ellipsis;}

#ntf-cli{background-color: #f7f7f7;border-radius: 10px;padding: 5px;width: 93%;margin: 0 auto;}
#ntf-cpt{display: grid;grid-template-columns: 8% 10% auto 30% 20%;margin-bottom: 5px;align-items: center;grid-gap: 10px;}
#ntf-cpt:hover{color:green;background-color: #eee;}
#ntf-cpt .Mui-checked,#ntf-cli .Mui-checked{
    color:green !important;
}
.ntf-div-img{width:50px;height: 50px;border-radius: 50%;background-color: white;object-fit: cover;padding:2px;}
.ntf-div-img .img-ntf{width: 50px;height: 50px;border-radius: 50%;object-fit: cover;}
#cpts-div{max-height:350px;overflow-y: auto;}

#forms-ntf{padding:15px 0 0 10px;}

.modl-add #row-1,.modl-add #row-2{display: grid;grid-template-columns: 33% 33% 33%;grid-gap: 5px;margin: 0 auto;width: 95%;}
.Modeles .rdt_TableCell div:first-child{
    white-space: normal !important;
}
#forms-ntf   .smallAutoFiltre .MuiOutlinedInput-adornedEnd{
    background-color: #eee;
  }

@media only screen  and  (max-width: 1250px) {
    .expender{padding:5px}
    .expender .expender-content #row-1{display: grid;grid-template-columns: repeat(auto-fit, 220px);grid-gap: 10px !important;}
    .expender .expender-content #row-2{display: grid;grid-template-columns: repeat(auto-fit, 220px);grid-gap: 10px !important;}
}
@media only screen  and  (max-width: 550px) {
    #new{width:100%;margin-left:auto;margin-right: auto;margin-bottom: 0px;}
    #new #row-1, #new #row-2,#new #row-3,#new #div-file { grid-gap:5px; grid-template-columns: repeat(auto-fit, 160px);  justify-content: center; display: grid;margin-bottom: 5px;}
    #Allreacts{grid-gap:10px;}
    .modl-add #row-1,.modl-add #row-2{display: grid;grid-template-columns: repeat(auto-fit, 220px);justify-content: center;}
    #ntf-cli{width: 100%;margin-left: 5px;}
    #ntf-cpt{grid-template-columns:8% 15% auto 30% 20%;}
}